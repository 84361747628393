<template>
    <div class="d-flex justify-center align-center fill-height">
        <v-card v-if="!sentEmail" style="max-width: 500px">
            <v-card-title class="pa-0">
                <div style="background: rgba(241,241,241,0.8);width:100%;height:150px" class="d-flex justify-center align-center">
                    <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
                </div>
                <div style="width:100%" class="d-flex justify-center">
                    <span class="primary--text title font-weight-bold mt-5 text-center">Join Nuvolos</span>
                </div>
            </v-card-title>

            <v-card-text v-if="nuvolosEmail">
                <div class="my-2">You will be able to join {{ spaceName }} (space) in {{ orgName }} (organization)</div>
                <v-btn
                    class="secondary"
                    block
                    :loading="sendingInvitation || validatingEmails"
                    :disabled="combinedEmail === '' || validatingEmails || sendingInvitation || invalidGenerator"
                    type="submit"
                    @click="validateEmailAndSignUp(true)"
                    >Next</v-btn
                >
            </v-card-text>
            <v-card-text v-else-if="loggedInEmail">
                <div class="my-4">You will be able to join {{ spaceName }} (space) in {{ orgName }} (organization)</div>
                <v-form ref="form" v-model="valid" required @submit="validateEmailAndSignUp(true)" onSubmit="return false;">
                    <span class="font-weight-bold">You will need to use the following email to log in to Nuvolos:</span>
                    <div class="text-center my-4 font-weight-bold secondary--text">{{ loggedInEmail }}</div>
                    <v-btn
                        class="secondary"
                        block
                        :loading="sendingInvitation || validatingEmails"
                        :disabled="!valid || loggedInEmail === '' || validatingEmails || sendingInvitation || invalidGenerator"
                        type="submit"
                        >Request sign up e-mail</v-btn
                    >
                </v-form>
                <InvitationErrorsDialog
                    :showDialog="showInvitationErrorsDialog"
                    :errorType="validationErrorType"
                    :invalidEmails="invalidEmailsList"
                    @proceedWithTheInvitation="trySignUp($event.value)"
                    @validateEmailsAgain="validateEmailAndSignUp($event.value)"
                    :isSignUpDialog="true"
                />
            </v-card-text>
            <v-card-text v-else>
                <ask-about-account>
                    <div v-if="nonSWITCH || (this.$auth.isAuthenticated() && !this.$data.loggedInEmail)">
                        <h4>Please provide your university email address below to receive a sign-up email</h4>
                        <div class="my-2">You will be able to join {{ spaceName }} (space) in {{ orgName }} (organization)</div>
                        <v-form ref="form" v-model="valid" required @submit="validateEmailAndSignUp(true)" onSubmit="return false;">
                            <v-text-field
                                :rules="emailRules"
                                v-model="email"
                                :loading="sendingInvitation || validatingEmails"
                                required
                                :disabled="invalidGenerator"
                                placeholder="Your university e-mail address"
                                autofocus
                            >
                            </v-text-field>
                            <v-text-field
                                v-model="emailValidate"
                                :loading="sendingInvitation || validatingEmails"
                                required
                                :disabled="invalidGenerator"
                                placeholder="Confirm your e-mail"
                            >
                            </v-text-field>
                            <v-alert type="warning" v-if="emailValidate !== '' && emailValidate !== email">
                                Emails don't match
                            </v-alert>
                            <v-btn
                                class="secondary"
                                block
                                :loading="sendingInvitation || validatingEmails"
                                :disabled="
                                    !valid ||
                                        email === '' ||
                                        validatingEmails ||
                                        sendingInvitation ||
                                        invalidGenerator ||
                                        emailValidate === '' ||
                                        email !== emailValidate
                                "
                                type="submit"
                                >Request sign up e-mail</v-btn
                            >
                        </v-form>
                        <InvitationErrorsDialog
                            :showDialog="showInvitationErrorsDialog"
                            :errorType="validationErrorType"
                            :invalidEmails="invalidEmailsList"
                            @proceedWithTheInvitation="trySignUp($event.value)"
                            @validateEmailsAgain="validateEmailAndSignUp($event.value)"
                            :isSignUpDialog="true"
                        />
                    </div>
                    <div v-else>
                        Select your institution to continue:
                        <institution-login style="min-width: 300px">
                            <v-btn @click="setNoSWITCH" text class="my-2" block>Create a new account</v-btn></institution-login
                        >
                    </div>
                </ask-about-account>
            </v-card-text>
        </v-card>
        <div v-else style="max-width: 500px" class="d-flex flex-column align-center justify-center font-weight-bold">
            <div class="text-center mb-3">
                <v-avatar color="white">
                    <v-icon x-large color="success">check_circle</v-icon>
                </v-avatar>
            </div>
            We have sent an invitation email to your email address
            <span class="text-center secondary--text">{{ combinedEmail }}</span>
            <span class="my-2">Please check your inbox and click on the link to create your account.</span>
            <span class="caption">(you can then close this page)</span>
        </div>
        <v-dialog :value="showError || invalidGenerator" max-width="600" :persistent="invalidGenerator">
            <v-card>
                <v-card-title>
                    Sign-up error
                </v-card-title>
                <v-card-text>
                    <v-alert type="error" class="mt-2"> {{ errorContent }}</v-alert>
                </v-card-text>
            </v-card>
        </v-dialog>
        <invitation-modal message="Creating invitation" :show="sendingInvitation" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { emailValidation } from '@/mixins/validateEmail'
import { isDateGreaterThanToday } from '@/utils'
import store from 'store'

const InvitationErrorsDialog = () => import('@/components/InvitationErrorsDialog')
const InstitutionLogin = () => import('@/modules/invitations/components/InstitutionLogin.vue')
const AskAboutAccount = () => import('@/modules/invitations/components/AskAboutAccount.vue')
const InvitationModal = () => import('@/modules/invitations/components/TheInvitationModal.vue')

export default {
    mixins: [emailValidation],
    components: { InvitationErrorsDialog, InstitutionLogin, AskAboutAccount, InvitationModal },
    data() {
        return {
            orgName: '',
            spaceName: '',
            email_domain_whitelist: [],
            showError: false,
            invalidGenerator: false,
            errorContent: 'An error has occured. If it persists, please contact support@nuvolos.cloud',
            email: '',
            emailValidate: '',
            emailRules: [
                v =>
                    this.$data.email_domain_whitelist.length === 0 ||
                    this.$data.email_domain_whitelist.some(wl => this.$data.email.endsWith(wl)) ||
                    'Email must end with one of: @' + this.$data.email_domain_whitelist.join(', @')
            ],
            matchValid: [v => v === this.$data.email || "E-mails don't match"],
            valid: false,
            sentEmail: false,
            sendingInvitation: false,
            noAccount: false,
            loggedInEmail: this.$auth.isAuthenticated() ? this.$auth.profile.email : '',
            nonSWITCH: false
        }
    },
    mounted() {
        this.$axios.get(`/invitations/invgen/${this.$route.params.token}`).then(response => {
            this.$data.email_domain_whitelist = response.data.email_domain_whitelist
            this.$data.orgName = response.data.org_long_id
            this.$data.spaceName = response.data.space_long_id
            if (response.data.is_valid === false && isDateGreaterThanToday(response.data.validity_timestamp) === false) {
                this.$data.invalidGenerator = true
                this.$data.errorContent = 'This invitation generator has expired. Please contact your lecturer or TA to get a valid invitation'
            } else if (response.data.is_valid === false) {
                this.$data.invalidGenerator = true
                this.$data.errorContent = 'This invitation generator has been exhausted. Please contact your lecturer or TA to get an invitation'
            }
        })
    },
    methods: {
        warning(event) {
            event.returnValue = ''
        },
        trySignUp(confirmed) {
            if (confirmed === true) {
                this.$data.showError = false
                this.$data.sendingInvitation = true
                store.set('nvLastEmail', this.combinedEmail)
                this.$axios
                    .post(`/invitations/invgen/${this.$route.params.token}`, { email: this.combinedEmail })
                    .then(response => {
                        if (response.data.url && response.data.url !== 'null') {
                            this.$data.sendingInvitation = false
                            this.$nextTick(() => {
                                window.location.href = response.data.url
                            })
                        } else {
                            this.$data.sentEmail = true
                            this.$store.dispatch('showSnackBar', {
                                snackBarText: 'Sign-up request successfully sent!',
                                snackBarTimeout: 5000,
                                snackBarIcon: 'check_circle'
                            })
                        }
                    })
                    .catch(error => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Sign-up request failed',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                        if (error.response && error.response.data && error.response.data.code === 'create_instances_with_single_editor_failed') {
                            this.$data.errorContent =
                                'A sign up request has already been sent to the provided email address, please check the email box or contact support@alphacruncher.com'
                        } else if (error.response && error.response.data && error.response.data.code === 'instance_already_exists') {
                            this.$data.errorContent =
                                'An instance associated with this email address has already been created. Try to login to Nuvolos with this email and if you encounter problems contact support@alphacruncher.com'
                            window.setTimeout(() => {
                                this.$router.push({ name: 'login', query: { login_hint: this.$data.combinedEmail } })
                            }, 5000)
                        } else if (error.response && error.response.data && error.response.data.code === 'invitation_generator_has_expired') {
                            this.$data.invalidGenerator = true
                            this.$data.errorContent = 'This invitation generator has expired. Please contact your lecturer or TA to get a valid invitation'
                        }

                        this.$data.showError = true
                    })
                    .finally(() => {
                        this.$data.sendingInvitation = false
                    })
            }
        },
        validateEmailAndSignUp(confirmed) {
            if (confirmed === true) {
                this.$data.showError = false
                this.validateEmailsWithPublicMailgunApi(this.combinedEmail).finally(() => {
                    if (
                        this.$data.emailsValidationError === false &&
                        this.$data.foundInvalidEmails === false &&
                        this.$data.emailValidatedWithPublicAPI === true
                    ) {
                        this.trySignUp(confirmed)
                    }
                })
            }
        },
        handleLoginEvent(e) {
            if (e.loggedIn === true) {
                this.$data.loggedInEmail = this.$auth.profile.email
            }
        },
        setNoSWITCH() {
            this.$data.nonSWITCH = true
        }
    },
    computed: {
        validationErrorType() {
            if (this.$data.foundInvalidEmails === true) {
                return this.$data.emailValidationErrorTypes.INVALID_EMAILS_ERROR
            } else if (this.$data.backendError === true || this.$data.emailsValidationError === true) {
                return this.$data.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
            }
            return null
        },
        showInvitationErrorsDialog() {
            if (this.$data.foundInvalidEmails === true || this.$data.backendError === true || this.$data.emailsValidationError === true) {
                return true
            }
            return false
        },
        nuvolosEmail() {
            return this.userInfo ? this.userInfo.email : null
        },
        combinedEmail() {
            return this.nuvolosEmail || this.loggedInEmail || this.$data.email
        },
        ...mapState(['userInfo'])
    }
}
</script>
