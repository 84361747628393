var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-center align-center fill-height" },
    [
      !_vm.sentEmail
        ? _c(
            "v-card",
            { staticStyle: { "max-width": "500px" } },
            [
              _c("v-card-title", { staticClass: "pa-0" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-center align-center",
                    staticStyle: {
                      background: "rgba(241,241,241,0.8)",
                      width: "100%",
                      height: "150px"
                    }
                  },
                  [
                    _c("v-img", {
                      staticClass: "py-5",
                      attrs: {
                        "max-width": "200",
                        "max-height": "22",
                        src: require("@/assets/Nuvolos-transparent.svg")
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-center",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "primary--text title font-weight-bold mt-5 text-center"
                      },
                      [_vm._v("Join Nuvolos")]
                    )
                  ]
                )
              ]),
              _vm.nuvolosEmail
                ? _c(
                    "v-card-text",
                    [
                      _c("div", { staticClass: "my-2" }, [
                        _vm._v(
                          "You will be able to join " +
                            _vm._s(_vm.spaceName) +
                            " (space) in " +
                            _vm._s(_vm.orgName) +
                            " (organization)"
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: {
                            block: "",
                            loading:
                              _vm.sendingInvitation || _vm.validatingEmails,
                            disabled:
                              _vm.combinedEmail === "" ||
                              _vm.validatingEmails ||
                              _vm.sendingInvitation ||
                              _vm.invalidGenerator,
                            type: "submit"
                          },
                          on: {
                            click: function($event) {
                              return _vm.validateEmailAndSignUp(true)
                            }
                          }
                        },
                        [_vm._v("Next")]
                      )
                    ],
                    1
                  )
                : _vm.loggedInEmail
                ? _c(
                    "v-card-text",
                    [
                      _c("div", { staticClass: "my-4" }, [
                        _vm._v(
                          "You will be able to join " +
                            _vm._s(_vm.spaceName) +
                            " (space) in " +
                            _vm._s(_vm.orgName) +
                            " (organization)"
                        )
                      ]),
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { required: "", onSubmit: "return false;" },
                          on: {
                            submit: function($event) {
                              return _vm.validateEmailAndSignUp(true)
                            }
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              "You will need to use the following email to log in to Nuvolos:"
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-center my-4 font-weight-bold secondary--text"
                            },
                            [_vm._v(_vm._s(_vm.loggedInEmail))]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary",
                              attrs: {
                                block: "",
                                loading:
                                  _vm.sendingInvitation || _vm.validatingEmails,
                                disabled:
                                  !_vm.valid ||
                                  _vm.loggedInEmail === "" ||
                                  _vm.validatingEmails ||
                                  _vm.sendingInvitation ||
                                  _vm.invalidGenerator,
                                type: "submit"
                              }
                            },
                            [_vm._v("Request sign up e-mail")]
                          )
                        ],
                        1
                      ),
                      _c("InvitationErrorsDialog", {
                        attrs: {
                          showDialog: _vm.showInvitationErrorsDialog,
                          errorType: _vm.validationErrorType,
                          invalidEmails: _vm.invalidEmailsList,
                          isSignUpDialog: true
                        },
                        on: {
                          proceedWithTheInvitation: function($event) {
                            return _vm.trySignUp($event.value)
                          },
                          validateEmailsAgain: function($event) {
                            return _vm.validateEmailAndSignUp($event.value)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "v-card-text",
                    [
                      _c("ask-about-account", [
                        _vm.nonSWITCH ||
                        (this.$auth.isAuthenticated() &&
                          !this.$data.loggedInEmail)
                          ? _c(
                              "div",
                              [
                                _c("h4", [
                                  _vm._v(
                                    "Please provide your university email address below to receive a sign-up email"
                                  )
                                ]),
                                _c("div", { staticClass: "my-2" }, [
                                  _vm._v(
                                    "You will be able to join " +
                                      _vm._s(_vm.spaceName) +
                                      " (space) in " +
                                      _vm._s(_vm.orgName) +
                                      " (organization)"
                                  )
                                ]),
                                _c(
                                  "v-form",
                                  {
                                    ref: "form",
                                    attrs: {
                                      required: "",
                                      onSubmit: "return false;"
                                    },
                                    on: {
                                      submit: function($event) {
                                        return _vm.validateEmailAndSignUp(true)
                                      }
                                    },
                                    model: {
                                      value: _vm.valid,
                                      callback: function($$v) {
                                        _vm.valid = $$v
                                      },
                                      expression: "valid"
                                    }
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        rules: _vm.emailRules,
                                        loading:
                                          _vm.sendingInvitation ||
                                          _vm.validatingEmails,
                                        required: "",
                                        disabled: _vm.invalidGenerator,
                                        placeholder:
                                          "Your university e-mail address",
                                        autofocus: ""
                                      },
                                      model: {
                                        value: _vm.email,
                                        callback: function($$v) {
                                          _vm.email = $$v
                                        },
                                        expression: "email"
                                      }
                                    }),
                                    _c("v-text-field", {
                                      attrs: {
                                        loading:
                                          _vm.sendingInvitation ||
                                          _vm.validatingEmails,
                                        required: "",
                                        disabled: _vm.invalidGenerator,
                                        placeholder: "Confirm your e-mail"
                                      },
                                      model: {
                                        value: _vm.emailValidate,
                                        callback: function($$v) {
                                          _vm.emailValidate = $$v
                                        },
                                        expression: "emailValidate"
                                      }
                                    }),
                                    _vm.emailValidate !== "" &&
                                    _vm.emailValidate !== _vm.email
                                      ? _c(
                                          "v-alert",
                                          { attrs: { type: "warning" } },
                                          [_vm._v(" Emails don't match ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "secondary",
                                        attrs: {
                                          block: "",
                                          loading:
                                            _vm.sendingInvitation ||
                                            _vm.validatingEmails,
                                          disabled:
                                            !_vm.valid ||
                                            _vm.email === "" ||
                                            _vm.validatingEmails ||
                                            _vm.sendingInvitation ||
                                            _vm.invalidGenerator ||
                                            _vm.emailValidate === "" ||
                                            _vm.email !== _vm.emailValidate,
                                          type: "submit"
                                        }
                                      },
                                      [_vm._v("Request sign up e-mail")]
                                    )
                                  ],
                                  1
                                ),
                                _c("InvitationErrorsDialog", {
                                  attrs: {
                                    showDialog: _vm.showInvitationErrorsDialog,
                                    errorType: _vm.validationErrorType,
                                    invalidEmails: _vm.invalidEmailsList,
                                    isSignUpDialog: true
                                  },
                                  on: {
                                    proceedWithTheInvitation: function($event) {
                                      return _vm.trySignUp($event.value)
                                    },
                                    validateEmailsAgain: function($event) {
                                      return _vm.validateEmailAndSignUp(
                                        $event.value
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _vm._v(
                                  " Select your institution to continue: "
                                ),
                                _c(
                                  "institution-login",
                                  { staticStyle: { "min-width": "300px" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "my-2",
                                        attrs: { text: "", block: "" },
                                        on: { click: _vm.setNoSWITCH }
                                      },
                                      [_vm._v("Create a new account")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass:
                "d-flex flex-column align-center justify-center font-weight-bold",
              staticStyle: { "max-width": "500px" }
            },
            [
              _c(
                "div",
                { staticClass: "text-center mb-3" },
                [
                  _c(
                    "v-avatar",
                    { attrs: { color: "white" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { "x-large": "", color: "success" } },
                        [_vm._v("check_circle")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(
                " We have sent an invitation email to your email address "
              ),
              _c("span", { staticClass: "text-center secondary--text" }, [
                _vm._v(_vm._s(_vm.combinedEmail))
              ]),
              _c("span", { staticClass: "my-2" }, [
                _vm._v(
                  "Please check your inbox and click on the link to create your account."
                )
              ]),
              _c("span", { staticClass: "caption" }, [
                _vm._v("(you can then close this page)")
              ])
            ]
          ),
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.showError || _vm.invalidGenerator,
            "max-width": "600",
            persistent: _vm.invalidGenerator
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Sign-up error ")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    { staticClass: "mt-2", attrs: { type: "error" } },
                    [_vm._v(" " + _vm._s(_vm.errorContent))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("invitation-modal", {
        attrs: { message: "Creating invitation", show: _vm.sendingInvitation }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }